import React from "react"
import Layout from "../components/layoutEn"
import SEO from "../components/layout/seo"

import companyPic from "../img/companyPic.png"
import noLimitIcon0 from "../img/icons/noLimitIcon_0.png"
import noLimitIcon1 from "../img/icons/noLimitIcon_1.png"
import noLimitIcon2 from "../img/icons/noLimitIcon_2.png"
import noLimitIcon3 from "../img/icons/noLimitIcon_3.png"
import noLimitIcon4 from "../img/icons/noLimitIcon_4.png"
import noLimitIcon5 from "../img/icons/noLimitIcon_5.png"
import noLimitIcon6 from "../img/icons/noLimitIcon_6.png"
import noLimitImg from "../img/noLimitImg.jpg"
import etriLogo from "../img/logo/logo_etri.png"
import miniLogo from "../img/logo/logo_mini.png"
import ttaLogo from "../img/logo/logo_tta.png"
import ieftLogo from "../img/logo/logo_ietf.png"
import ituLogo from "../img/logo/logo_itu.png"
import keicLogo from "../img/logo/logo_keic.png"
import koselLogo from "../img/logo/logo_kosel.png"
import isoLogo from "../img/logo/logo_iso.png"
import w3cLogo from "../img/logo/logo_w3c.png"
import ocfLogo from "../img/logo/logo_ocf.png"
import kbizLogo from "../img/logo/logo_kbiz.png"

import believeIcon0 from "../img/icons/believe_01.png"
import believeIcon1 from "../img/icons/believe_02.png"
import believeIcon2 from "../img/icons/believe_03.png"

import certIcon from "../img/cert/icon_cert.png"
import licenIcon from "../img/cert/icon_licen.png"

import ttaCert from "../img/cert/m2mTech_cert_tta.pdf"
import patentCert from "../img/cert/m2mTech_cert_patent.pdf"
import isoCert from "../img/cert/m2mTech_cert_iso.pdf"
import busiCert from "../img/cert/m2mTech_cert_business.pdf"
import ventureCert from "../img/cert/m2mTech_cert_venture.pdf"
import memberCert from "../img/cert/m2mTech_cert_member.jpg"
import softwareCert from "../img/cert/m2mTech_cert_software.pdf"
import rndCert from "../img/cert/m2mTech_cert_rnd.jpg"

//import noLimitIcon0 from "./icons/noLimitIcon_0.png";
//import noLimitIcon from "./icons";

const AboutUs = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="aboutUsWrap">
      <div className="sectionWrap overviewWrap">
        <p className="imgWrap">
          <img src={companyPic} alt="img" />
        </p>
        <div className="textWrap">
          <h2 style={{ fontSize: "37px" }}>
            Specialized in Artificial Intelligence-Internet of Things (AIoT),{" "}
            <br />
            M2MTech Co., Ltd
          </h2>
          <p className="bodyText">
            M2MTech Co., Ltd. is a smart convergence company that provides more
            than expected value to customers through smart M2M / IoT technology
            and services. The latest technology trend is that the world is
            moving towards a hyper-connected society. A network-based
            hyper-connected society enables communication between all objects,
            enabling a smart society that can access information without spatial
            and temporal restrictions. Through the research and development of
            products/services that enable people-to-people, people-to-objects,
            and things-to-objects, M2MTech strives to make our society more
            secure, convenient, and more happy. I want to be a company.
            <br />
            <br />
            We take the heart of developing a core product/service that meets
            global standards and a culture of people-oriented corporate culture
            that is respected and trusted by customers through constant
            challenges and new technological innovations.
            <span>CEO Jin-Kee Kim</span>
          </p>
        </div>
      </div>
      <div className="sectionWrap noLimitWrap">
        <div className="textWrap">
          <h2>There’s no boundary.</h2>
          <p className="bodyText">
            M2MTech creates products and services with greater added value by
            converging technologies in various fields. We are carrying out
            projects in many fields such as artificial intelligence, IoT, data
            analysis processing, and the cloud.
          </p>
        </div>
        <ul className="noLimitUl">
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon0} alt="" />
            </p>
            <p>
              <span className="eng">Artificial intelligence IoT</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon1} alt="" />
            </p>
            <p>
              <span className="eng">Edge computing</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon2} alt="" />
            </p>
            <p>
              <span className="eng">Cloud</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon3} alt="" />
            </p>
            <p>
              <span className="eng">divice health check, monitoring</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon4} alt="" />
            </p>
            <p>
              <span className="eng">platform</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon5} alt="" />
            </p>
            <p>
              <span className="eng">data processing</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon6} alt="" />
            </p>
            <p>
              <span className="eng">ERP service</span>
            </p>
          </li>
        </ul>
        <div className="noLimitImg">
          <img src={noLimitImg} alt="" />
        </div>
        <div className="textWrap">
          <h2>Who we work with</h2>
          <p className="bodyText">
            M2MTech has established the standards of industrial sectors such as
            the Information and Communication Technology Association and is
            conducting technical approvals by organizing with the international
            standardization organization of national public institutions.
            National technologies such as TTF standard establishment (in
            progress), national standards, Korea Elevator Industry Cooperative
            collective standard establishment (completed), IYU-T SG 20 proposal,
            ISO TC 178 participation (in progress) through continuous
            cooperation with government departments and international
            organizations I will participate in the improvement.
          </p>
        </div>
        <ul className="workWUl">
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>Ministry of Public Administration and Security</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>Ministry of Trade, Industry and Energy</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>Ministry of Science and ICT</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>National Institute of Technology and Standards</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={etriLogo} alt="" />
            </p>
            <p>ETRI Electronics and Telecommunications Research Institute</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ttaLogo} alt="" />
            </p>
            <p>TTA Telecommunications Technology Association</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ieftLogo} alt="" />
            </p>
            <p>IETF Internet Engineering Task Force</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ituLogo} alt="" />
            </p>
            <p>ITU-T International Telecommunications Union</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={keicLogo} alt="" />
            </p>
            <p>Korea Federation of Elevator/Lift Industry Co-operatives</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={isoLogo} alt="" />
            </p>
            <p>ISO 178, ISO 9001</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={w3cLogo} alt="" />
            </p>
            <p>W3C</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ocfLogo} alt="" />
            </p>
            <p>Open Connectivity Foundation</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={koselLogo} alt="" />
            </p>
            <p>Korean Association for the Advancement of Elevator/Lift</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={kbizLogo} alt="" />
            </p>
            <p>Korea Federation of Small and Medium Business</p>
          </li>
        </ul>
      </div>
      <div className="sectionWrap believeWrap">
        <div className="textWrap">
          <h2>Things we believe in.</h2>
        </div>
        <ul className="believeUl">
          <li>
            <p className="imgWrap">
              <img src={believeIcon0} alt="" />
            </p>
            <p>Business based on humanity.</p>
            <p className="bodyText">
              <strong>M2MTech puts people first.</strong>
              <br />
              We will create a corporate culture that puts people first in the
              center of communication and collaboration.
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={believeIcon1} alt="" />
            </p>
            <p>Corporate transparency and social responsibility</p>
            <p className="bodyText">
              <strong>‘財上平如水 人中直似衡’</strong>
              <br />
              ‘A fortune is equal to water, and a man is equal to a scale.’ We
              will become a sustainable company by making efforts to secure
              corporate transparency and promote public interest.
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={believeIcon2} alt="" />
            </p>
            <p>Creating new value</p>
            <p className="bodyText">
              <strong>We create new value.</strong>
              <br />
              We will grow into a group of experts focused on creating new
              values rather than competition, and oriented towards a happy
              working community.
            </p>
          </li>
        </ul>
      </div>
      <div className="sectionWrap certWrap">
        <p className="textWrap">Business Licence &amp; Certificates</p>
        <ul>
          <li>
            <img src={licenIcon} alt="" />
            <a href={busiCert} target="_blank" rel="noreferrer">
              Business license
            </a>
          </li>
          <li>
            <img src={licenIcon} alt="" />
            <a href={softwareCert} target="_blank" rel="noreferrer">
              Software business company Verification
            </a>
          </li>
          <li>
            <img src={licenIcon} alt="" />
            <a href={ventureCert} target="_blank" rel="noreferrer">
              Verification of Venture Businesses
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={patentCert} target="_blank" rel="noreferrer">
              patent certificate
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={isoCert} target="_blank" rel="noreferrer">
              Management System Certificate
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={rndCert} target="_blank" rel="noreferrer">
              Certificate of Accreditation for Corporate Affiliated Research
              Institute
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={ttaCert} target="_blank" rel="noreferrer">
              TTA Certificate
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={memberCert} target="_blank" rel="noreferrer">
              Member of the Korea Industrial Technology Promotion Association
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default AboutUs
